.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.css-1pzfmz2-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.1em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 16.5px 14px;
  padding: 8.5px 14px;
}


.desktop-layout {
  display: block;
}

.mobile-layout {
  display: none;
}

@media (max-width: 600px) {
  .desktop-layout {
    display: none;
  }

  .mobile-layout {
    display: block;
  }
}
.custom-text-field .MuiInputBase-root {
  height: 35px; /* Điều chỉnh chiều cao ở đây */
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 37px;
}

.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {

  padding: 9.5px 14px !important;;
  padding-right: 0;
}

.css-jiabcu-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3 !important;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 8px !important;
}

.css-9iq4h1-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  padding: 6px 8px !important;
 
}
.css-cokf1l-MuiListItemIcon-root {
  min-width: 40px !important;

}
.css-1k80kex {
  min-width: 40px !important;
  color: rgba(0, 0, 0, 0.54);
  flex-shrink: 0;
  display: inline-flex;
}
.css-qwdxx6 {
 
  padding: 8px 0px 8px 14px !important;
}
.css-fyswvn {

  line-height: 1 !important;
 
}
.css-rizt0-MuiTypography-root {

  line-height: 1 !important;
 
}
.fixed-bottom-button {
  position: fixed;
  bottom: 20px; /* Khoảng cách từ dưới cùng */
  right: 20px;  /* Khoảng cách từ bên phải */
  padding: 10px 20px;
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  width: 100%;
}
.bottom-button{
  padding: 10px 20px;
  color: white;
  border: none;
  background-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  width: 45%;
  margin: 5px;

}
.bottom-button:hover {
  background-color: #0056b3;
}
.add-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  color: blue;
  font-size: 18px;
  cursor: pointer;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.add-button:hover {
  background-color: #218838; /* Màu khi hover */
}